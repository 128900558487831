import BasicLayout from "main/layouts/BasicLayout/BasicLayout";
import { Button } from 'react-bootstrap';
import { useCurrentUser , hasRole} from 'main/utils/currentUser';
import MenuItemsTable from "main/components/MenuItems/Table";
import { useBackend } from "main/utils/useBackend";


const CreateButton = ({currentUser}) => {
    if (hasRole(currentUser, "ROLE_ADMIN")) {
        return (
            <Button
                variant="primary"
                href="/ucsbdiningcommonsmenuitems/create"
                style={{ float: "right" }}
            >
                Create Menu Item
            </Button>
        )
    }
    return null;
}

export default function UCSBDiningCommonsMenuItemsIndexPage() {

  const currentUser = useCurrentUser();

  const { data: menuItems, error: _error, status: _status } =
    useBackend(
      // Stryker disable next-line all : don't test internal caching of React Query
      ["/api/ucsbdiningcommonsmenuitem/all"],
      { method: "GET", url: "/api/ucsbdiningcommonsmenuitem/all" },
      []
    );
  // Stryker disable all : placeholder for future implementation
  return (
    <BasicLayout>
      <div className="pt-2">
        <CreateButton currentUser={currentUser}/>
        <MenuItemsTable menuItems={menuItems} currentUser={currentUser}/>
      </div>
    </BasicLayout>
  )
}
