import BasicLayout from "main/layouts/BasicLayout/BasicLayout";
import { Navigate } from 'react-router-dom'
import { useBackendMutation } from "main/utils/useBackend";
import { toast } from "react-toastify";
import MenuItemForm from "main/components/MenuItems/Form";

export default function CreatePage({storybook=false}) {

  const objectToAxiosParams = (menuItem) => ({
    url: "/api/ucsbdiningcommonsmenuitem",
    method: "POST",
    params: menuItem
  });

  const onSuccess = (menuItem) => {
    toast(`New menu item created - id: ${menuItem.id} name: ${menuItem.name}`);
  }

  const mutation = useBackendMutation(
    objectToAxiosParams,
     { onSuccess },
     // Stryker disable next-line all : hard to set up test for caching
     ["/api/ucsbdiningcommonsmenuitem/all"]
     );

  const { isSuccess } = mutation

  const onSubmit = async (data) => {
    mutation.mutate(data);
  }

  if (isSuccess && !storybook) {
    return <Navigate to="/ucsbdiningcommonsmenuitems" />
  }

  return (
    <BasicLayout>
      <div className="pt-2">
        <h1>Create New Menu Item</h1>
        <MenuItemForm submitAction={onSubmit} />
      </div>
    </BasicLayout>
  )
}
